<template>
  <div>
    <a-modal
        :width="900"

        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-row :gutter="24" type="flex">
          <a-col :span="12" class="mb-24">

          <a-form-item label='Full Name'>
          <a-input
              v-decorator="[
              'name',
               { rules: [{ required: true, message: 'Full Name is required' }] ,
               initialValue:selectedItem?selectedItem.name:'',
              }
            ]"
          />
        </a-form-item>
            </a-col>
          <a-col :span="12" class="mb-24">

          <a-form-item label='Email'>
          <a-input
              v-decorator="[
              'email',
               { rules: [{ required: true, message: 'Email is required' }] ,
               initialValue:selectedItem?selectedItem.email:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-24">

          <a-form-item label='Phone'>
          <a-input
              v-decorator="[
              'phone',
               { rules: [{ required: true, message: 'Phone is required' }] ,
               initialValue:selectedItem?selectedItem.phone:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-24">

          <a-form-item label='Password'>
            <a-input-password
              v-decorator="[
              'password',
               { rules: !editMode?[{ required: true, message: 'Password is required' }]:'' ,
               initialValue:selectedItem?selectedItem.password:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="24" class="mb-24">
        <a-form-item label='Description'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
                  type='textarea'
              v-decorator="['description',{
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="24" class="mb-24">
          <a-form-item class='collection-create-form_last-form-item' label="Status">
          <a-radio-group
              v-decorator="[
              'status',
              {rules: [{ required: true, message: 'Password is required' }],
                initialValue: Object.keys(selectedItem).length!=0?selectedItem.status.toString():'true',
              }
            ]"
          >
            <a-radio value='true'>Active</a-radio>
            <a-radio value='false'>In-active</a-radio>
          </a-radio-group>
        </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ['editMode', 'visible','selectedItem'],
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Admin User' : 'Create New Admin User'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.id
          this.confirmLoading = true
          if (this.editMode){
            this.$store.dispatch("UPDATE_ADMIN_USER", values)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_ADMIN_USER", values)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },

    cancelForm(){
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
      if (this.editMode&&Object.keys(this.selectedItem).length!=0){
        this.id=this.selectedItem._id
    }
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>